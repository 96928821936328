import Cookies from "../helpers/Cookies";
import * as _ from "lodash";
import { setWithExpiry, getWithExpiry } from "../helpers/utils";

function any(arr, func = (a) => !!a) {
  return arr.reduce((acc, ...p) => acc || func(...p), false);
}

const PRS_SETTINGS = [
  "invoice-summary",
  "kpi-penalty-configs",
  "ncr-category",
  "prs-configuration-percentage-settings",
  "quality-audit-zone-config",
  "quality-audit-configuration-percentage",
  "sla-penalty-configs",
  "user-type-percentages",
  "nestrom-api",
];

export default function (dataProvider) {
  return {
    login: ({ username, password }) => {
      const identifier = username; // strapi expects 'identifier' and not 'username'
      const request = new Request(dataProvider + "/auth/local", {
        method: "POST",
        body: JSON.stringify({ identifier, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      });
      return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((response) => {
          Cookies.setCookie("token", response.jwt, 1);
          Cookies.setCookie("role", response.user.role.name, 1);
          localStorage.setItem("user", JSON.stringify(response.user));
          Cookies.setCookie("roleId", response.user.role._id, 1);
        });
    },

    logout: () => {
      Cookies.deleteCookie("token");
      Cookies.deleteCookie("role");
      Cookies.deleteCookie("roleId");
      localStorage.removeItem("permissions");
      localStorage.removeItem("user");
      localStorage.removeItem("cost-centers");
      localStorage.removeItem("vendors");
      return Promise.resolve();
    },

    checkAuth: () => {
      return Cookies.getCookie("token") ? Promise.resolve() : Promise.reject();
    },

    checkError: ({ status }) => {
      if (status === 401 || status === 403) {
        Cookies.deleteCookie("token");
        Cookies.deleteCookie("role");
        Cookies.deleteCookie("roleId");
        localStorage.removeItem("permissions");
        localStorage.removeItem("user");
        return Promise.reject();
      }
      return Promise.resolve();
    },

    getPermissions: () => {
      const permissions = getWithExpiry("permissions");
      if (permissions) return Promise.resolve(permissions);

      const token = Cookies.getCookie("token");
      const roleId = Cookies.getCookie("roleId");
      const request = new Request(
        `${dataProvider}/users-permissions/roles/${roleId}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          }),
        }
      );

      return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then(({ role: { permissions } }) => {
          setWithExpiry("permissions", permissions);
          return permissions;
        });
    },
    isAllowed: (permissions, type, action) => {
      let permissionsObj = getPermissionPerType(permissions, type, action);
      if (Array.isArray(permissionsObj))
        return any(permissionsObj, (e) => e[action].enabled);
      return permissionsObj ? permissionsObj[action].enabled : permissionsObj;
    },
    getPermissionPerType,
  };
}

const notEnabled = { enabled: false };
const defaultDisabled = {
  find: notEnabled,
  update: notEnabled,
  create: notEnabled,
};

function getPermissionPerType(permissions, type, action) {
  let def = { ...defaultDisabled, [action]: { ...notEnabled } };
  if (!permissions) return def;
  switch (type) {
    case "users":
      return _.result(permissions, "users-permissions.controllers.user", def);

    case "roles":
      return _.result(permissions, "users-permissions.controllers.user", def);

    case "prsConfigs":
      return !permissions["application"]
        ? def
        : PRS_SETTINGS.map((e) => {
            return _.result(permissions, `application.controllers.${e}`, def);
          });

    default:
      return { ...notEnabled, ..._.result(permissions, `application.controllers.${type}`, def)};
  }
}
