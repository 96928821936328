import { UPDATE } from "react-admin";

import { formatCurrency } from "../../helpers/utils";
import { dataProvider } from "../../provider/provider";

const totalRowStyle = {
  backgroundColor: "#bebebe",
  fontWeight: "bold",
  border: "none",
};
const colorTotal = ({ row }) => {
  return row._id === "Total" || row.id === "Total" ? totalRowStyle : {};
};

const inValidValue = (val) => val > 100 || val < 0;

function KPI_penltyStyleCel({ row, accessor }, rows, i, isIcon) {
  return validate_KPI({ row, accessor }, rows, i)
    ? {}
    : {
        backgroundColor: isIcon ? "#d30000" : "darkRed",
        color: "white",
      };
}

function validate_KPI({ row, accessor }, rows, i) {
  let invalide = false;
  let lastInd = rows.length - 1;
  if (inValidValue(row[accessor])) return invalide;
  if (
    row.lowerBound >= row.upperBound &&
    (accessor === "upperBound" || accessor === "lowerBound")
  )
    return invalide;

  if (i === 0) {
    if (accessor === "lowerBound" && row.lowerBound - 1 !== rows[1].upperBound)
      return invalide;
    if (accessor === "upperBound" && row.upperBound !== 100) return invalide;
    return true;
  }
  if (i === lastInd) {
    if (
      accessor === "upperBound" &&
      row.upperBound + 1 !== rows[lastInd - 1].lowerBound
    )
      return invalide;
    if (accessor === "lowerBound" && row.lowerBound > 0) return invalide;
    return true;
  }
  if (
    accessor === "lowerBound" &&
    row.lowerBound - 1 !== rows[i + 1].upperBound
  )
    return invalide;
  if (
    accessor === "upperBound" &&
    row.upperBound + 1 !== rows[i - 1].lowerBound
  )
    return invalide;
  return true;
}

const settingsConfigs = {
  "kpi-penalty-configs": {
    width: "70%",
    columns: (editable) => [
      {
        Header: "KPI Performance Requirements",
        columns: [
          {
            accessor: "performanceZone",
            Header: "Performance Zones",
            stylePerCell: ({ row, accessor }) => ({
              backgroundColor: row.backgroundColor,
              color: row.fontColor,
            }),
          },
          { accessor: "bound", Header: "Score" },
          {
            accessor: "upperBound",
            Header: "Upper Bound",
            isPercentage: true,
            editable,
            stylePerCell: KPI_penltyStyleCel,
          },
          {
            accessor: "lowerBound",
            Header: "Lower Bound",
            isPercentage: true,
            editable,
            stylePerCell: KPI_penltyStyleCel,
          },
        ],
      },
      {
        Header: "KPI Penalties",
        columns: [
          {
            accessor: "penatlyPercentageOfMonthlyInvoice",
            Header: "% Monthly Invoice",
            stylePerCell: KPI_penltyStyleCel,
            editable,
          },
        ],
      },
    ],
    handleResponseAPI: ({ data }) => {
      // TODO: remove faking data error
      // data[3].upperBound = data[3].upperBound + 1;
      // data[data.length - 1].lowerBound = -5;
      // data[0].upperBound = 105;
      data = data
        .sort((ele1, ele2) => ele2["upperBound"] - ele1["upperBound"])
        .map((doc) => {
          // data.forEach((doc) => {
          doc.bound =
            doc.upperBound === 100
              ? `${doc.lowerBound}% +`
              : doc.lowerBound === 0
              ? `- ${doc.upperBound}%`
              : `${doc.lowerBound}% - ${doc.upperBound}%`;
          doc.editable = true;
          return doc;
        });
      return data;
    },
    validate: ({ data }) => {
      return data.reduce((errors, obj, i) => {
        for (let accessor of [
          "upperBound",
          "lowerBound",
          "penatlyPercentageOfMonthlyInvoice",
        ]) {
          let valid = validate_KPI({ row: obj, accessor }, data, i);
          if (!valid)
            errors.push([
              obj,
              accessor,
              `${accessor} for: "${obj["performanceZone"]}" is Invalid`,
            ]);
        }
        return errors;
      }, []);
    },
  },
  "invoice-summary-settings": {
    allowEditingOldMonths: true,
    width: "50%",
    columns: (editable) => [
      {
        Header: "Contract Data",
        columns: [
          {
            accessor: "type",
            Header: "Type",
          },
          {
            accessor: "value",
            Header: "Value",
            editable,
          },
        ],
      },
    ],
    handleResponseAPI: ({ data }) => {
      if (!data.monthlyValueContract) return [];
      return [
        {
          id: 1,
          currency: "AED",
          type: "Monthly Value - Contract",
          fieldName: "monthlyValueContract",
          value: formatCurrency(data.monthlyValueContract, "AED"),
          value_original: data.monthlyValueContract,
          editable: true,
        },
      ];
    },
    onEdit: (params) => {
      console.log("invoice summary settings update", params);
      let {
        record,
        newValue,
        data: { data },
      } = params;
      let fieldName = record.fieldName;
      let updateObj = { id: data._id, data: { [fieldName]: newValue } };
      console.log("updateObj", updateObj);
      return dataProvider(UPDATE, "invoice-summary-settings", updateObj);
    },
  },
  "ncr-categories": {
    width: "100%",
    columns: (editable, data) => {
      let cols = [
      {
        Header: "NCR PENALTY",
        columns: [{ Header: "", accessor: "name" }],
      },
      {
        Header: "Criticality",
        columns: [{ Header: "", accessor: "Criticality" }],
      }];
      let columnsLabels = {};

      data.forEach(({ penaltyConfigs }) => penaltyConfigs.forEach(({ label }) => columnsLabels[label] = true));
      return [
        ...cols,
        ...Object.keys(columnsLabels).map(label => ({ Header: label, columns: [{ Header: "AED", accessor: label, editable }] }))
      ];
    },
    handleResponseAPI: ({ data }) => {
      console.log("data", data);
      function createData(name, Criticality, penaltyConfigs, id) {
        let obj = { name, Criticality, penaltyConfigs, id, _id: id };
        penaltyConfigs.forEach(({ label, penalty }) => {
          obj[`${label}`] = formatCurrency(penalty, "");
        });
        obj.editable = true;
        return obj;
      }

      let rows = data.map(
        ({ penaltyConfigs, criticality, description, _id }) => {
          return createData(description, criticality, penaltyConfigs, _id);
        }
      );

      return rows;
    },
    onEdit: (params) => {
      console.log("ncr-categories update", params);
      let { record, newValue, field } = params;
      let updateObj = {
        id: record._id,
        data: {
          penaltyConfigs: record.penaltyConfigs.map(
            ({ label, lowerBound, penalty, upperBound, _id }, i) => {
              return label !== field
                ? { label, lowerBound, upperBound, _id, penalty }
                : { label, lowerBound, upperBound, _id, penalty: newValue };
            }
          ),
        },
      };
      console.log("updateObj", updateObj);
      // return new Promise((res) => setTimeout(res, 3000)); // TODO:
      return dataProvider(UPDATE, "ncr-categories", updateObj);
    },
  },
  "prs-configuration-percentage-settings": {
    width: "50%",
    columns: (editable) => [
      {
        Header: "Percentages",
        columns: [
          { accessor: "type", Header: "Type", stylePerCell: colorTotal },
          {
            accessor: "value",
            Header: "Value",
            editable,
            stylePerCell: ({ row, accessor }) => {
              return row.fieldName === "Total" && row.value_original.toFixed(2) != "100.00"
                ? {
                    ...colorTotal({ row }),
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                  }
                : colorTotal({ row });
            },
          },
        ],
      },
    ],
    handleResponseAPI: ({ data }) => {
      console.log("data", data);
      data = Array.isArray(data) ? data[0] : data;
      let rows = [
        {
          value: `${data["qualityAuditPercentage"]} %`,
          fieldName: "qualityAuditPercentage",
          id: "qualityAuditPercentage",
          value_original: data["qualityAuditPercentage"],
          type: "Quality Audit Percentage",
          editable: true,
        },
        {
          value: `${data["SLA_Percentage"]} %`,
          fieldName: "SLA_Percentage",
          id: "SLA_Percentage",
          value_original: data["SLA_Percentage"],
          type: "SLA Percentage",
          editable: true,
        },
        {
          value: `${data["KPI_Percentage"]} %`,
          fieldName: "KPI_Percentage",
          id: "KPI_Percentage",
          value_original: data["KPI_Percentage"],
          type: "KPI Percentage",
          editable: true,
        },
        {
          value: `${data["NCR_Percentage"]} %`,
          fieldName: "NCR_Percentage",
          id: "NCR_Percentage",
          value_original: data["NCR_Percentage"],
          type: "NCR Percentage",
          editable: true,
        },
        {
          value: `${data["SPPV_Percentage"]} %`,
          fieldName: "SPPV_Percentage",
          id: "SPPV_Percentage",
          value_original: data["SPPV_Percentage"],
          type: "SPPV Percentage",
          editable: true,
        },
        {
          value: `${data["OER_Percentage"]} %`,
          fieldName: "OER_Percentage",
          id: "OER_Percentage",
          value_original: data["OER_Percentage"],
          type: "OER Percentage",
          editable: true,
        },
        {
          value: `${data["OPM_Percentage"]} %`,
          fieldName: "OPM_Percentage",
          id: "OPM_Percentage",
          value_original: data["OPM_Percentage"],
          type: "OPM Percentage",
          editable: true,
        },
        {
          value: `${data["RegulatoryCompliance_Percentage"]} %`,
          fieldName: "RegulatoryCompliance_Percentage",
          id: "RegulatoryCompliance_Percentage",
          value_original: data["RegulatoryCompliance_Percentage"],
          type: "Regulatory Compliance Percentage",
          editable: true,
        },
      ].filter(e => Object.hasOwnProperty.call(data, e.id));

      let totalFromRows = rows.filter(r => data[r.fieldName])
      let total = totalFromRows.reduce((acc, e) => acc + data[e.id], 0);
      rows.push({
        value: `${total} %`,
        fieldName: "Total",
        id: "Total",
        value_original: total,
        type: "Total",
      });
      return rows;
    },
    onEdit: (updates) => {
      console.log("prs-configuration-percentage-settings update", updates);
      let data = updates.reduce((acc, [obj, accessor, newVal]) => {
        acc[obj.fieldName] = newVal;
        return acc;
      }, {});

      let updateObj = { id: updates[0][3].data._id, data };
      console.log("updateObj", updateObj);
      // return new Promise((res) => setTimeout(res, 3000)); // TODO:
      return dataProvider(UPDATE, "prs-configuration-percentage-settings", updateObj);
    },
    validate: ({ data }) => {
      let obj = data[data.length - 1];
      let invalids = [];
      let sum = data.reduce((acc, obj) => {
        let { value_original, fieldName } = obj;
        if (value_original < 0) {
          invalids.push([obj, "value", "Percentage Can't Be Negative"]);
        }
        return acc + (fieldName === "Total" ? 0 : value_original);
      }, 0);
      obj["value_original"] = sum;
      obj["value"] = `${sum} %`;
      if (sum.toFixed(2) != "100.00")
        invalids.push([obj, "Total", `Total is: ${sum} Must Be 100%`]);
      return invalids;
    },
  },
  "quality-audit-zone-configs": {
    width: "100%",
    columns: (editable) => [
      {
        Header: "Zones Configs",
        columns: [
          {
            Header: "zoneId",
            accessor: "zone_location_mapping.zoneId",
            editable: false,
            stylePerCell: colorTotal,
          },
          {
            Header: "Criticality",
            accessor: "criticality",
            editable,
            stylePerCell: colorTotal,
          },
          {
            Header: "Exponential Criticality",
            accessor: "exponentialCriticality",
            stylePerCell: colorTotal,
            editable,
          },
          {
            Header: "Value To Score",
            accessor: "valueToScore",
            editable,
            stylePerCell: ({ row, accessor }) => {
              return row.id === "Total" && row.valueToScore_original.toFixed(2) != "100.00"
                ? {
                    ...colorTotal({ row }),
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                  }
                : colorTotal({ row });
            },
          },
        ],
      },
    ],
    handleResponseAPI: ({ data }) => {
      console.log("data", data);
      if (!data.length) return [];
      let totalPercentage = 0;
      let rows = data.map((r) => {
        totalPercentage += r.valueToScore;
        return {
          ...r,
          valueToScore_original: r.valueToScore,
          valueToScore: `${r.valueToScore} %`,
          editable: true,
        };
      });
      rows.push({
        id: "Total",
        zone_location_mapping: { zoneId: "Total" },
        valueToScore: `${totalPercentage.toFixed(3)} %`,
        valueToScore_original: totalPercentage,
        exponentialCriticality: "",
        criticality: "",
        editable: false,
      });
      return rows;
    },
    validate: ({ data }) => {
      let obj = data[data.length - 1];
      let invalids = [];
      let sum = data.reduce((acc, obj) => {
        let { valueToScore_original, id } = obj;
        if (valueToScore_original === 0) {
          invalids.push([obj, "valueToScore", "Value To Score Can't Be 0"]);
        } else if (valueToScore_original < 0) {
          invalids.push([obj, "valueToScore", "Percentage Can't Be Negative"]);
        }

        return acc + (id === "Total" ? 0 : valueToScore_original);
      }, 0);
      obj["valueToScore_original"] = sum;
      obj["valueToScore"] = `${sum.toFixed(3)} %`;
      console.log(sum.toFixed(2) != "100.00", `sum.toFixed(2) != "100.00"`);
      if (sum.toFixed(2) != "100.00")
        invalids.push([
          obj,
          "Total",
          `Total is: ${sum.toFixed(3)} Must Be 100%`,
        ]);
      return invalids;
    },
  },
  "quality-audit-configuration-percentages": {
    width: "50%",
    columns: (editable) => [
      {
        Header: "Percentages Per Type",
        columns: [
          { accessor: "name", Header: "Type", stylePerCell: colorTotal },
          {
            accessor: "percentage",
            Header: "Percentage",
            editable,
            stylePerCell: ({ row, accessor }) => {
              return row.fieldName === "Total" &&
                row.percentage_original.toFixed(2) != "100.00"
                ? {
                    ...colorTotal({ row }),
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                  }
                : colorTotal({ row });
            },
          },
        ],
      },
    ],
    handleResponseAPI: ({ data }) => {
      let vals = data;
      let total =
        vals.snapAuditWeighting +
        vals.jointAuditWeighting +
        vals.technicalAuditWeighting;
      return [
        {
          id: "Technical Audit",
          name: "Technical Audit",
          percentage: `${vals.technicalAuditWeighting} %`,
          percentage_original: vals.technicalAuditWeighting,
          fieldName: "technicalAuditWeighting",
          editable: true,
        },
        {
          id: "Joint Audit",
          name: "Joint Audit",
          percentage: `${vals.jointAuditWeighting} %`,
          percentage_original: vals.jointAuditWeighting,
          fieldName: "jointAuditWeighting",
          editable: true,
        },
        {
          id: "Snap Audit",
          name: "Snap Audit",
          percentage: `${vals.snapAuditWeighting} %`,
          percentage_original: vals.snapAuditWeighting,
          fieldName: "snapAuditWeighting",
          editable: true,
        },
        {
          id: "Total",
          name: "Total",
          percentage: total + " %",
          percentage_original: total,
          fieldName: "Total",
          editable: false,
        },
      ];
    },
    onEdit: (updates) => {
      console.log("quality-audit-configuration-percentages update", updates);
      let data = updates.reduce((acc, [obj, accessor, newVal]) => {
        acc[obj.fieldName] = newVal;
        return acc;
      }, {});

      let updateObj = { id: updates[0][3].data._id, data };
      console.log("updateObj", updateObj);
      // return new Promise((res) => setTimeout(res, 3000)); // TODO:
      return dataProvider(
        UPDATE,
        "quality-audit-configuration-percentages",
        updateObj
      );
    },
    validate: ({ data }) => {
      let obj = data[data.length - 1];
      let errors = [];
      let sum = data.reduce((acc, obj) => {
        let { percentage_original, fieldName } = obj;
        if (percentage_original === 0)
          errors.push([obj, "percentage", "Value Can't be 0"]);
        return acc + (fieldName === "Total" ? 0 : percentage_original);
      }, 0);
      obj["percentage_original"] = sum;
      obj["percentage"] = `${sum} %`;
      if (sum.toFixed(2) != "100.00")
        errors.push([obj, "Total", `Total is: ${sum} % Must Be 100%`]);
      return errors;
    },
  },
  "sla-penalty-configs-settings": {
    width: "100%",
    columns: (editable) => [
      { Header: "KPI", columns: [{ Header: "", accessor: "KPI" }] },
      {
        Header: "Description",
        columns: [{ Header: "", accessor: "description" }],
      },
      {
        Header: "Failure Penalty",
        columns: [
          { accessor: "failure_penalty.0.failure", Header: "Failure" },
          {
            accessor: "failure_penalty.0.penalty",
            Header: "Penalty AED",
            editable: editable,
          },
          { accessor: "failure_penalty.1.failure", Header: "Failure" },
          {
            accessor: "failure_penalty.1.penalty",
            Header: "Penalty AED",
            editable: editable,
          },
          { accessor: "failure_penalty.2.failure", Header: "Failure" },
          {
            accessor: "failure_penalty.2.penalty",
            Header: "Penalty AED",
            editable: editable,
          },
        ],
      },
    ],
    handleResponseAPI: ({ data }) => {
      console.log("data", data);
      let rows = data.map((obj) => {
        let row = { ...obj, editable: true }; // TODO: obj.isPersisted
        return row;
      });
      return rows;
    },
    onEdit: (params) => {
      console.log("sla-penalty-configs-settings update", params);
      let { record, newValue, field } = params;
      let index = Number.parseInt(field.split(".")[1]);
      let updateObj = {
        id: record._id,
        data: {
          failure_penalty: record.failure_penalty.map((r, i) => {
            return i !== index ? r : { ...r, penalty: newValue };
          }),
        },
      };
      console.log("updateObj", updateObj);
      // return new Promise((res) => setTimeout(res, 3000)); // TODO:
      return dataProvider(UPDATE, "sla-penalty-configs-settings", updateObj);
    },
  },
  "user-type-percentages": {
    width: "50%",
    columns: (editable) => [
      {
        Header: "User Percentage",
        columns: [
          {
            accessor: "type",
            Header: "Type",
          },
          {
            accessor: "value",
            Header: "Percentage",
            editable,
          },
          {
            accessor: "cap",
            Header: "Cap",
            editable,
          },
        ],
      },
    ],
    handleResponseAPI: ({ data }) => {
      if (!data.assetManagerTechnical) return [];
      return [
        {
          id: 1,
          type: "Asset Manager Technical Audit",
          fieldName: "assetManagerTechnical",
          value: `${data["assetManagerTechnical"]} %`,
          value_original: data["assetManagerTechnical"],
          cap: data["assetManagerTechnical_cap"],
          cap_original: data["assetManagerTechnical_cap"],
          editable: true,
        },
        {
          id: 2,
          type: "Asset Manager Snap Audit",
          fieldName: "assetManagerSnap",
          value: `${data["assetManagerSnap"]} %`,
          value_original: data["assetManagerSnap"],
          cap: data["assetManagerSnap_cap"],
          cap_original: data["assetManagerSnap_cap"],
          editable: true,
        },
        {
          id: 3,
          type: "SP.FM Technical Audit",
          fieldName: "SP_FM_Technical",
          value: `${data["SP_FM_Technical"]} %`,
          value_original: data["SP_FM_Technical"],
          cap: data["SP_FM_Technical_cap"],
          cap_original: data["SP_FM_Technical_cap"],
          editable: true,
        },
        {
          id: 4,
          type: "SP.FM Snap Audit",
          fieldName: "SP_FM_Snap",
          value: `${data["SP_FM_Snap"]} %`,
          value_original: data["SP_FM_Snap"],
          cap: data["SP_FM_Snap_cap"],
          cap_original: data["SP_FM_Snap_cap"],
          editable: true,
        },
        {
          id: 5,
          type: "supervisor",
          fieldName: "supervisor_Technical",
          value: `${data["supervisor_Technical"]} %`,
          value_original: data["supervisor_Technical"],
          cap: data["supervisor_Technical_cap"],
          cap_original: data["supervisor_Technical_cap"],
          editable: true,
        },
      ];
    },
    onEdit: (params) => {
      console.log("user-type-percentages update", params);
      let {
        record,
        field,
        newValue,
        data: { data },
      } = params;
      let fieldName = record.fieldName;
      let updateObj = { id: data._id, data: { [field == "value" ? fieldName : `${fieldName}_cap`]: newValue } };
      console.log("updateObj", updateObj);
      // return new Promise((res) => setTimeout(res, 3000)); // TODO:
      return dataProvider(UPDATE, "user-type-percentages", updateObj);
    },
  },
  "nestrom-api/users": {
    width: "50%",
    columns: (editable) => [
      {
        Header: "User Assignment",
        columns: [
          {
            accessor: "name",
            Header: "Name",
            editable: false,
          },
          {
            accessor: "role",
            Header: "Role",
            editable: false,
          },
          {
            accessor: "vendors",
            Header: "Vendors",
            editable: false,
          },
          {
            accessor: "cost_centers",
            Header: "Cost Centers",
            editable: false,
          },
          {
            accessor: "specialConditions",
            Header: "Special Conditions",
            editable: false,
          },
        ],
      },
    ],
    handleResponseAPI: ({data}) => {
      return data.map((ele) => (
        {
          id: ele.id || ele._id,
          name: ele.name,
          role: ele.role,
          vendors: ele.vendors.join(", "),
          cost_centers: ele.cost_centers.join(", "),
          editable: false,
          specialConditions: ele.skills.filter((skill) => skill.match(/^Not /)).join(", ") || "-"
        })
      );
    },
    onEdit: (params) => {},
  },
};

export default settingsConfigs;
