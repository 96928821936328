import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from "react-admin";
import { resources } from "./configs";
import {
  adjustQueryForStrapi,
  isComponentsKeys,
  listAndReferneceCustomizedResponse,
} from "./strapiRequestsCustomization";
/**
 * Maps react-admin queries to a simple REST API
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (
  apiUrl,
  httpClient = fetchUtils.fetchJson,
  uploadFields = []
) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = "";
    const options = {};
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        url = `${apiUrl}/${resource}?${adjustQueryForStrapi(params, resource)}`;
        break;
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "PUT";
        // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
        const {
          created_at,
          updated_at,
          createdAt,
          updatedAt,
          ...data
        } = params.data;
        options.body = JSON.stringify(data);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "DELETE";
        break;
      case "CUSTOM":
        url = `${apiUrl}/${resource}`;
        options.method = params.method;
        if (params.method == "POST") options.body = JSON.stringify(params.data);
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  // Determines if there are new files to upload
  const determineUploadFieldNames = (params) => {
    if (!params.data) return [];

    // Check if the field names are mentioned in the uploadFields
    // and verify there are new files being added
    const requestUplaodFieldNames = [];
    Object.keys(params.data).forEach((field) => {
      let fieldData = params.data[field];
      if (uploadFields.includes(field)) {
        fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData;
        fieldData.filter((f) => f && f.rawFile instanceof File).length > 0 &&
          requestUplaodFieldNames.push(field);
      }
    });

    // Return an array of field names where new files are added
    return requestUplaodFieldNames;
  };

  // Handles file uploading for CREATE and UPDATE types
  const handleFileUpload = (type, resource, params, uploadFieldNames) => {
    const {
      created_at,
      updated_at,
      createdAt,
      updatedAt,
      ...data
    } = params.data;
    const id = type === UPDATE ? `/${params.id}` : "";
    const url = `${apiUrl}/${resource}${id}`;
    const requestMethod = type === UPDATE ? "PUT" : "POST";
    const formData = new FormData();

    for (let fieldName of uploadFieldNames) {
      let fieldData = params.data[fieldName];
      fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData;
      const existingFileIds = [];

      for (let item of fieldData) {
        item.rawFile instanceof File
          ? formData.append(`files.${fieldName}`, item.rawFile)
          : existingFileIds.push(item.id || item._id);
      }

      data[fieldName] = [...existingFileIds];
    }
    formData.append("data", JSON.stringify(data));

    return httpClient(url, {
      method: requestMethod,
      body: formData,
    }).then((response) => ({ data: replaceRefObjectsWithIds(response.json) }));
  };

  // Replace reference objects with reference object IDs
  const replaceRefObjectsWithIds = (json) => {
    Object.keys(json).forEach((key) => {
      const fd = json[key]; // field data
      const referenceKeys = [];
      if (fd && (fd.id || fd._id) && !fd.mime) {
        if (!isComponentsKeys(key)) {
          json[key] = fd.id || fd._id;
        } else {
          delete fd.id;
        }
      } else if (Array.isArray(fd) && fd.length > 0 && !fd[0].mime) {
        fd.map((item) => referenceKeys.push(item.id || item._id));
        if (!isComponentsKeys(key)) {
          json[key] = referenceKeys;
        }
      }
    });
    return json;
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { json, total, body, status } = response;
    switch (type) {
      case GET_ONE:
        return { status, data: json };
      // return { data: replaceRefObjectsWithIds(json) };
      case GET_LIST:
      case GET_MANY_REFERENCE:
        return {
          status,
          data: Array.isArray(json)
            ? json.map((e, i) => ({ ...e, id: e.id || e._id || i }))
            : json,
          total,
        };
      case CREATE:
        return { status, data: { ...params.data, id: json.id } };
      case DELETE:
        return { status, data: { id: null } };
      default:
        return { status, data: json || body };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params, customOptions = {}) => {
    // Handle file uploading
    const uploadFieldNames = determineUploadFieldNames(params);
    if (uploadFieldNames.length > 0) {
      return handleFileUpload(type, resource, params, uploadFieldNames);
    }

    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id) => {
          // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
          const {
            created_at,
            updated_at,
            createdAt,
            updatedAt,
            ...data
          } = params.data;
          return httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(data),
          });
        })
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE",
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    //strapi doesn't handle filters in GET route
    if (type === GET_MANY) {
      return Promise.all(
        params.ids.map((i) =>
          httpClient(`${apiUrl}/${resource}/${i.id || i._id || i}`, {
            method: "GET",
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);

    // Get total via model/count endpoint
    if (type === GET_MANY_REFERENCE || type === GET_LIST) {
      let { settingsRequiredFilters, requiredFilters } = Object.values(resources).find(
        (e) => e.name === resource
      );
      let missing = (customOptions.isSettings ? (settingsRequiredFilters || requiredFilters) : requiredFilters).filter((r) => !params.filter[r]).length;
      if (missing) {
        // console.warn("All Filters Are Mandatory", resource)
        return new Promise((res, rej) => {
          res({ total: 0, data: [] });
          // rej("Please Select Cost Center & Vendor & Year & Month")
        });
      }
      const { url: urlForCount } = convertDataRequestToHTTP(
        type,
        resource + "/count",
        params
      );
      return Promise.all([
        httpClient(url, options),
        // httpClient(urlForCount, options),
      ]).then(async (promises) => {
        promises[0].json = listAndReferneceCustomizedResponse(
          resource,
          promises[0].json
        );
        if (
          typeof promises[0].json === "object" &&
          promises[0].json.hasOwnProperty("total")
        ) {
          if (!promises[0].json.data) {
            return promises[0].json;
          }
          let exrtaKeys = { ...promises[0].json, data: null };
          let addToObj = {};
          Object.keys(exrtaKeys).forEach((k) => {
            if (exrtaKeys[k] != null) {
              addToObj[k + "_response"] = exrtaKeys[k];
            }
          });
          const response = {
            json: promises[0].json.data.map((doc, i) => {
              doc = { ...addToObj, ...doc };
              doc["total_response"] = doc["total_response"].toFixed(2);
              if (!doc.id) {
                doc.id = doc._id || i;
              }
              return doc;
            }),
            total: promises[0].json.data.length,
          };
          if (resource == "cost-centers" || resource == "vendors")
            localStorage.setItem(resource, JSON.stringify(response.json));
          return convertHTTPResponse(response, type, resource, params);
        }
        try {
          promises[1] = await httpClient(urlForCount, options);
        } catch (e) {
          promises[1] = { json: promises[0].json.length };
        }
        const response = {
          ...promises[0],
          // Add total for further use
          total: parseInt(promises[1].json, 10),
        };
        if (resource == "cost-centers" || resource == "vendors")
          localStorage.setItem(resource, JSON.stringify(promises[0].json));
        return convertHTTPResponse(response, type, resource, params);
      });
    } else {
      return httpClient(url, options).then((response) => {
        return convertHTTPResponse(response, type, resource, params);
      });
    }
  };
};
