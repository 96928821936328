import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import "date-fns";
import Box from "@material-ui/core/Box";

import ResourceSelect from "../helpers/ResourceSelect";
import { YearMonthSelect } from "../helpers/commonFilters";

const dateInputUseStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    // paddingTop: "8px",
  },
}));

function DatePickers({ onChange, filterValues }) {
  const classes = dateInputUseStyles();

  return (
    <Box
      component="div"
      display="inline"
      p={1}
      m={1}
    >
      <FormControl variant="filled" className={classes.formControl} error>
        <YearMonthSelect onChange={onChange} filterValues={filterValues} />
      </FormControl>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ListSelectUser = (props) => {
  return (
    <ResourceSelect
      name={"nestromUserID"}
      handleChange={props.onChange}
      value={props.filterValues.nestromUserID}
      configResource={"nestrom-api/users"}
      alias={"Auditor"}
    />
  );
};

const ListSelectCC = (props) => {
  return (
    <ResourceSelect
      name={"cost_center"}
      handleChange={props.onChange}
      value={props.filterValues.cost_center}
      configResource={"cost_centers"}
      valueToSelect={"nestromOrginizationId"}
      default={"All"}
      disabled={props.filterValues.nestromUserID === "invalid"}
      // handleData={(cost_centers => cost_centers.filter(c => this.props.user.organizations.indexOf))}
    />
  );
};

function allFilters(requiredFilters, filterValues) {
  return requiredFilters.reduce(
    (acc, ele) => acc && filterValues[ele] && filterValues[ele] !== "invalid",
    true
  );
}

const CreateFilters = ({
  requiredFilters,
  filtersUpdated,
  filterValues,
  onClickFetch,
  width,
}) => {
  console.log("requiredFilters", { requiredFilters, filterValues });
  const classes = useStyles();
  let allFiltersSelected = allFilters(requiredFilters, filterValues);

  return (
    <div style={{ width }}>
      <ListSelectUser onChange={filtersUpdated} filterValues={filterValues} />
      <ListSelectCC onChange={filtersUpdated} filterValues={filterValues} />
      <DatePickers onChange={filtersUpdated} filterValues={filterValues} />

      <FormControl
        variant="filled"
        className={classes.formControl}
        style={{ float: "right" }}
      >
        <Button
          aria-label="Apply Filter"
          onClick={onClickFetch}
          variant="outlined"
          color="primary"
          size="large"
          style={{ minHeight: "56px" }}
          disabled={!allFiltersSelected}
          startIcon={<SearchIcon />}
        >
          Apply Filter
        </Button>
      </FormControl>
    </div>
  );
};

export default CreateFilters;
