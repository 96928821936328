import * as React from "react";
import { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import lodashGet from "lodash/get";
import { setSidebarVisibility, useLocale } from "ra-core";
import { MenuItemLink, usePermissions } from "react-admin";
import { History as HistoryIcon } from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import NestromIcon from "./NestromIcon";

import { authProvider } from "../../provider/provider";
import SettingsItems from "./settingsMenue";
import { linkToNestrom } from "../../provider/configs";

export const DRAWER_WIDTH = 240;
export const CLOSED_DRAWER_WIDTH = 55;

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    docked: {},
    paper: {},
    paperAnchorLeft: {},
    paperAnchorRight: {},
    paperAnchorTop: {},
    paperAnchorBottom: {},
    paperAnchorDockedLeft: {},
    paperAnchorDockedTop: {},
    paperAnchorDockedRight: {},
    paperAnchorDockedBottom: {},
    modal: {},
    drawerPaper: {
      position: "relative",
      height: "100%",
      overflowX: "hidden",
      width: (props) =>
        props.open
          ? lodashGet(theme, "sidebar.width", DRAWER_WIDTH)
          : lodashGet(theme, "sidebar.closedWidth", CLOSED_DRAWER_WIDTH),
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: "transparent",
      borderRight: "none",
      [theme.breakpoints.only("xs")]: {
        marginTop: 0,
        height: "100vh",
        position: "inherit",
        backgroundColor: theme.palette.background.default,
      },
      [theme.breakpoints.up("md")]: {
        border: "none",
      },
      zIndex: "inherit",
    },
  }),
  { name: "RaSidebar" }
);

const CustomSidebar = (props) => {
  const {
    children,
    closedSize,
    size,
    classes: classesOverride,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useLocale(); // force redraw on locale change
  const handleClose = () => dispatch(setSidebarVisibility(false));
  const toggleSidebar = () => dispatch(setSidebarVisibility(!open));
  const { drawerPaper, ...classes } = useStyles({ ...props, open });

  return isXSmall ? (
    <Drawer
      variant="temporary"
      open={open}
      PaperProps={{
        className: drawerPaper,
      }}
      onClose={toggleSidebar}
      classes={classes}
      {...rest}
    >
      {cloneElement(Children.only(children), {
        onMenuClick: handleClose,
      })}
      <NestromMenueItem open={open} {...props} />
      <AuditLogs open={open} {...props} />
      <SettingsItems open={open} />
    </Drawer>
  ) : isSmall ? (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        className: drawerPaper,
      }}
      onClose={toggleSidebar}
      classes={classes}
      {...rest}
    >
      {cloneElement(Children.only(children), {
        onMenuClick: handleClose,
      })}
      <NestromMenueItem open={open} {...props} />
      <AuditLogs open={open} {...props} />
      <SettingsItems open={open} />
    </Drawer>
  ) : (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        className: drawerPaper,
      }}
      onClose={toggleSidebar}
      classes={classes}
      {...rest}
    >
      {children}
      <NestromMenueItem open={open} {...props} />
      <AuditLogs open={open} {...props} />
      <div
        style={{
          position: "fixed",
          width: "inherit",
          bottom: 0,
          textAlign: "center",
          paddingBottom: 10,
        }}
      >
        <SettingsItems open={open} />
      </div>
    </Drawer>
  );
};

CustomSidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

function NestromMenueItem(props) {
  const classes = useStyles({ ...props });
  return (
    <>
      <br />
      <Divider variant="middle" />
      <br />
      <MenuItemLink
        key={"Nestrom"}
        to={"/NestromTab"}
        primaryText={"Nestrom"}
        leftIcon={<NestromIcon style={{ color: "#3352a2" }} />}
        onClick={() => window.open(linkToNestrom, "_blank")}
        color="primary"
        className={classes.menueItem}
      />
    </>
  );
};

function AuditLogs(props) {
  const classes = useStyles({ ...props });
  const { permissions } = usePermissions();
  const audiLogsFind = authProvider.isAllowed(permissions, "audit-log", "find");
  if (!audiLogsFind) return null;

  return (
    <>
      <br />
      <Divider variant="middle" />
      <br />
      <MenuItemLink
        key={"audit-logs"}
        to={"/audit-logs"}
        primaryText={"Audit Logs"}
        leftIcon={<HistoryIcon style={{ color: "#757575" }} />}
        onClick={props.onMenuClick}
        color="primary"
        className={classes.menueItem}
      />
    </>
  );
}
export default CustomSidebar;
