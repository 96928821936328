import React from "react";
import { UPDATE, GET_LIST } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";

import { resources } from "../../provider/configs";
import { dataProvider } from "../../provider/provider";
import WithHeaderGroupTable from "../../helpers/WithHeaderGroupTable.js";
import AlertDialog from "../../customise/confirmationDialog";
import settingsTablesConfigs from "./settingsTablesConfigs";

const styles = {
  hide: { display: "none" },
  container: { width: "50%" },
  loader: {
    top: "50%",
    margin: "auto",
    // position: "relative",
    width: "50px",
  },
};

function allowEdit({ month, year }, configResource) {
  let currDate = new Date();
  let currYear = currDate.getFullYear();
  let currMonth = currDate.getMonth() + 1;
  let allowEditingOldMonths =
    settingsTablesConfigs[configResource].allowEditingOldMonths;
  let isPreviouseMonth =
    year < currYear || (year == currYear && month <= currMonth);
    
  return (allowEditingOldMonths && isPreviouseMonth) || month === currMonth;
}

class SettingsComponent extends React.Component {
  state = {
    openDialog: false,
    configs: settingsTablesConfigs[this.props.configResource],
    resource: { ...resources[this.props.configResource], requiredFilters: resources[this.props.configResource].settingsRequiredFilters || resources[this.props.configResource].requiredFilters },
    permission: this.props.permission,
    error: null,
    notification: null,
    isLoaded: true,
    isFetched: false,
    // isLoaded: false,
    columns: settingsTablesConfigs[this.props.configResource].columns(
      this.props.permission.update?.enabled &&
        allowEdit(this.props.filterValues, this.props.configResource),
        []
    ),
    rows: [],
    data: [],
    loading: false,
    filter: {
      vendor: this.props.filterValues["vendor"],
      cost_center: this.props.filterValues["cost_center"],
      year: this.props.filterValues["year"],
      month: this.props.filterValues["month"],
    },
  };

  get(filters = {}) {
    let filter = {};
    this.state.resource.requiredFilters.forEach(
      (filterKey) =>
        (filter[filterKey] =
          filters[filterKey] || this.props.filterValues[filterKey])
    );

    this._asyncRequest = dataProvider(GET_LIST, this.state.resource.name, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter,
    },
    { isSettings: true },
    );
    return this._asyncRequest;
  }

  handleClose(confirm) {
    if (confirm === "Ok") {
      let {
        updateData: { record, field, newValue },
        data,
      } = this.state;
      this.setState({ isLoaded: false, openDialog: false });
      let updatePromise = null;
      if (this.state.configs.onEdit) {
        updatePromise = this.state.configs.onEdit({
          record,
          field,
          newValue,
          data,
        });
      } else {
        let updateObj = { id: record.id, data: { [field]: newValue } };
        updatePromise = dataProvider(
          UPDATE,
          this.state.resource.name,
          updateObj
        );
      }

      updatePromise.then(this.reFetch.bind(this));
    } else {
      this.setState({ openDialog: false });
    }
  }

  onEdit(record, field, newValue) {
    newValue = Number.parseFloat(newValue);
    if (isNaN(newValue))
      return this.setState({ notification: "Please Enter A Number" });
    if (newValue < 0)
      return this.setState({ notification: "Please Enter A Positive Number" });
    if (this.state.configs.validateUpdate) {
      let error = this.state.configs.validateUpdate({ [field]: newValue });
      if (error) {
        return this.setState({ notification: error });
      }
    }

    this.setState({
      openDialog: true,
      notification: null,
      updateData: { record, field, newValue },
    });
  }

  componentDidMount(p) {
    this._isMounted = true;
    if (this.state.resource.requiredFilters.length === 0) this.reFetch(); // only fetch on apply button click
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this._asyncRequest) {
      this._asyncRequest.cancel && this._asyncRequest.cancel();
    }
  }

  allFilters() {
    return this.state.resource.requiredFilters.reduce(
      (acc, f) =>
        !!this.state.filter[f] && this.state.filter[f] !== "invalid" && acc,
      true
    );
  }

  reFetch(filters) {
    if (!this.allFilters()) return;
    if (this.state.isLoaded && !this.state.loading) {
      this.setState({ isLoaded: false, loading: true, notification: null });
    }
    this.get(filters).then(
      (data) => {
        if (!this._isMounted) return;
        let rows = this.state.configs.handleResponseAPI(data);
        if (data.data.isPersisted) {
          this.setState({
            isLoaded: true,
            isFetched: true,
            rows,
            data,
            columns: this.state.configs.columns(false, data.data),
            loading: false,
            notification: null,
          });
        } else {
          this.setState({
            isLoaded: true,
            isFetched: true,
            data,
            rows,
            columns: this.state.configs.columns(this.props.permission.update?.enabled &&
              allowEdit(this.props.filterValues, this.props.configResource), data.data),
            loading: false,
            notification: null,
          });
        }
      },
      (error) =>
        this.setState({
          isLoaded: true,
          isFetched: true,
          error,
          rows: [],
          loading: false,
          notification: null,
        })
    );
  }
  filterChanged(obj1, obj2) {
    return (
      obj1.cost_center !== obj2.cost_center ||
      obj1.vendor !== obj2.vendor ||
      obj1.year !== obj2.year ||
      obj1.month !== obj2.month
    );
  }

  UNSAFE_componentWillReceiveProps(p) {
    let isFilterChanged = this.filterChanged(p.filterValues, this.state.filter);
    if (p.fetch) this.reFetch(p.filterValues);

    let editPermissionChanged =
      p.permission.update?.enabled !== this.state.permission.update?.enabled;
    if (!isFilterChanged && !editPermissionChanged) return;

    let newState = isFilterChanged
      ? {
          filter: { ...p.filterValues },
          isLoaded: true,
          isFetched: false,
          rows: [],
          error: null,
          notification: null,
          columns: this.state.configs.columns(
            p.permission.update?.enabled &&
              allowEdit(p.filterValues, this.props.configResource),
            []
          )
        }
      : {};

    this.setState(newState);
  }

  render() {
    const { classes } = this.props;
    const {
      error,
      isLoaded,
      rows,
      openDialog,
      configs,
      loading,
      notification,
      isFetched,
      resource,
    } = this.state;

    let allFiltersSelected = this.allFilters();
    if (error) {
      return (
        <Alert severity="error">
          <AlertTitle>Error:</AlertTitle>
          {error.message}
        </Alert>
      );
    } else if (!isLoaded && allFiltersSelected) {
      if (!loading && resource.requiredFilters.length === 0) this.reFetch(); // only fetch on apply button click
      return (
        <div classes={classes.loader}>
          <CircularProgress />
        </div>
      );
    } else if (isLoaded && allFiltersSelected) {
      return (
        <>
          <div style={{ width: configs.width }}>
            <WithHeaderGroupTable
              columns={this.state.columns}
              rows={rows}
              onEdit={this.onEdit.bind(this)}
              message={
                isFetched ? null : "Click Search Button To Apply Filters"
              }
            />
            {!!notification && (
              <Alert severity="error">
                <AlertTitle>Update Validation Error:</AlertTitle>
                {notification}
              </Alert>
            )}
            <AlertDialog
              open={openDialog}
              messages={{
                id: "deduction-summary",
                title: "Confirmation!",
                description: () => <span>{"Are You Sure?"}</span>,
                ok: "Update",
                cancel: "Cancel",
              }}
              handler={this.handleClose.bind(this)}
            />
          </div>
        </>
      );
    } else {
      return (
        <div style={{ width: configs.width }}>
          <WithHeaderGroupTable
            columns={this.state.columns}
            rows={rows}
            onEdit={this.onEdit.bind(this)}
            message={"please select all filters"}
          />
        </div>
      );
    }
  }
}

export default withStyles(styles)(SettingsComponent);
